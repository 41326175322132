var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('content-with-sidebar', {
    staticClass: "blog-wrapper"
  }, [_c('b-row', {
    staticClass: "blog-list-wrapper"
  }, [_vm._l(_vm.blogList, function (blog) {
    return _c('b-col', {
      key: blog.img,
      attrs: {
        "md": "6"
      }
    }, [_c('b-card', {
      attrs: {
        "tag": "article",
        "no-body": ""
      }
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'pages-blog-detail',
          params: {
            id: blog.id
          }
        }
      }
    }, [_c('b-img', {
      staticClass: "card-img-top",
      attrs: {
        "src": blog.img,
        "alt": blog.img.slice(5)
      }
    })], 1), _c('b-card-body', [_c('b-card-title', [_c('b-link', {
      staticClass: "blog-title-truncate text-body-heading",
      attrs: {
        "to": {
          name: 'pages-blog-detail',
          params: {
            id: blog.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(blog.title) + " ")])], 1), _c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', {
      staticClass: "mr-50",
      attrs: {
        "vertical-align": "center"
      }
    }, [_c('b-avatar', {
      attrs: {
        "href": "javascript:void(0)",
        "size": "24",
        "src": blog.avatar
      }
    })], 1), _c('b-media-body', [_c('small', {
      staticClass: "text-muted mr-50"
    }, [_vm._v("by")]), _c('small', [_c('b-link', {
      staticClass: "text-body"
    }, [_vm._v(_vm._s(blog.userFullName))])], 1), _c('span', {
      staticClass: "text-muted ml-75 mr-50"
    }, [_vm._v("|")]), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(blog.blogPosted))])])], 1), _c('div', {
      staticClass: "my-1 py-25"
    }, _vm._l(blog.tags, function (tag, index) {
      return _c('b-link', {
        key: index
      }, [_c('b-badge', {
        staticClass: "mr-75",
        attrs: {
          "pill": "",
          "variant": _vm.tagsColor(tag)
        }
      }, [_vm._v(" " + _vm._s(tag) + " ")])], 1);
    }), 1), _c('b-card-text', {
      staticClass: "blog-content-truncate"
    }, [_vm._v(" " + _vm._s(blog.excerpt) + " ")]), _c('hr'), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('b-link', {
      attrs: {
        "to": {
          path: "/pages/blog/".concat(blog.id, "#blogComment")
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center text-body"
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "MessageSquareIcon"
      }
    }), _c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(_vm.kFormatter(blog.comment)) + " Comments")])], 1)]), _c('b-link', {
      staticClass: "font-weight-bold",
      attrs: {
        "to": {
          name: 'pages-blog-detail',
          params: {
            id: blog.id
          }
        }
      }
    }, [_vm._v(" Read More ")])], 1)], 1)], 1)], 1);
  }), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "my-2"
  }, [_c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.rows,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])], 2), _c('div', {
    staticClass: "blog-sidebar py-2 py-lg-0",
    attrs: {
      "slot": "sidebar"
    },
    slot: "sidebar"
  }, [_c('b-form-group', {
    staticClass: "blog-search"
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "search-input",
      "placeholder": "Search here"
    },
    model: {
      value: _vm.search_query,
      callback: function callback($$v) {
        _vm.search_query = $$v;
      },
      expression: "search_query"
    }
  }), _c('b-input-group-append', {
    staticClass: "cursor-pointer",
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "blog-recent-posts mt-3"
  }, [_c('h6', {
    staticClass: "section-label mb-75"
  }, [_vm._v(" Recent Posts ")]), _vm._l(_vm.blogSidebar.recentPosts, function (recentpost, index) {
    return _c('b-media', {
      key: recentpost.img,
      class: index ? 'mt-2' : '',
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', {
      staticClass: "mr-2"
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'pages-blog-detail',
          params: {
            id: recentpost.id
          }
        }
      }
    }, [_c('b-img', {
      attrs: {
        "src": recentpost.img,
        "alt": recentpost.img.slice(6),
        "width": "100",
        "rounded": "",
        "height": "70"
      }
    })], 1)], 1), _c('b-media-body', [_c('h6', {
      staticClass: "blog-recent-post-title"
    }, [_c('b-link', {
      staticClass: "text-body-heading",
      attrs: {
        "to": {
          name: 'pages-blog-detail',
          params: {
            id: recentpost.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(recentpost.title) + " ")])], 1), _c('span', {
      staticClass: "text-muted mb-0"
    }, [_vm._v(" " + _vm._s(recentpost.createdTime) + " ")])])], 1);
  })], 2), _c('div', {
    staticClass: "blog-categories mt-3"
  }, [_c('h6', {
    staticClass: "section-label mb-1"
  }, [_vm._v(" Categories ")]), _vm._l(_vm.blogSidebar.categories, function (category) {
    return _c('div', {
      key: category.icon,
      staticClass: "d-flex justify-content-start align-items-center mb-75"
    }, [_c('b-link', [_c('b-avatar', {
      staticClass: "mr-75",
      attrs: {
        "rounded": "",
        "size": "32",
        "variant": _vm.tagsColor(category.category)
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": category.icon,
        "size": "16"
      }
    })], 1)], 1), _c('b-link', [_c('div', {
      staticClass: "blog-category-title text-body"
    }, [_vm._v(" " + _vm._s(category.category) + " ")])])], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }